/** @jsx jsx */
import { jsx, Styled, Input } from 'theme-ui';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { Layout, Container, Button } from '../components';
import * as api from '../utils/api';
import {
  showNotification,
  SEVERITY,
  hideAllNotifications,
} from '../state/notifications';
import { locNavigate } from '../state/session';
import getLanguage from '../utils/getLanguage';
import * as analytics from '../utils/analytics';

const RequestEmailPage = ({ pageContext }) => {
  analytics.usePageCategory('omat_sivut');
  const localize = useSelector(state => state.localize);
  const translate = getTranslate(localize);
  const [email, setEmail] = useState('');
  const dispatch = useDispatch();
  const language = useSelector(state => getLanguage(state));

  const updateDetails = async (email, language) => {
    dispatch(hideAllNotifications());
    if (!email || email.length < 1) {
      return;
    }
    console.log(email);
    try {
      const response = await api.updateProfile({ email });
      console.log(response);
      setEmail('');
      dispatch(
        showNotification('register.message.updateDetailsSuccess', SEVERITY.INFO)
      );
      try {
        // request email validation
        const response = await api.resendValidationEmailWithToken(
          email,
          language
        );
        console.log(response);
      } catch (err) {
        //do nothing
        console.log('Resending validation email failed', err);
      }
      //setTimeout(() => {
      dispatch(locNavigate('/register-success'));
      //}, 3000);
    } catch (err) {
      console.log('Updating details failed', err);
      dispatch(showNotification('register.message.updateDetailsFailed'));
    }
  };

  return (
    <Layout
      title={translate('register.requestEmailHeader')}
      paths={pageContext.paths}
      locale={pageContext.locale || 'en'}
    >
      <Container sx={{ maxWidth: 1024, p: [3, 4] }}>
        <Styled.h1>{translate('register.requestEmailHeader')}</Styled.h1>
        <Styled.p>{translate('register.requestEmailText')}</Styled.p>
        {translate('register.form.email')}
        <Input value={email} onChange={e => setEmail(e.target.value)} />
        <Button
          onClick={e => {
            e.preventDefault();
            updateDetails(email, language);
          }}
          sx={{ mt: 3 }}
        >
          {translate('register.form.registerButton')}
        </Button>
      </Container>
    </Layout>
  );
};

export default RequestEmailPage;
